import React from 'react'

import {useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import { Helmet } from 'react-helmet'
import Container from '../components/Container';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BlogPostPreview from '../components/BlogPostPreview';
import SEO from '../components/SEO';
import { graphql } from 'gatsby'

import {
  Box,
  Heading,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';

const About = ({ data }) => { 
  const posts = data.allContentfulBlogPost.edges;
  const siteTitle = data.site?.siteMetadata.title;
  const description = data.site?.siteMetadata.description;
  const {t} = useTranslation()
  const context = React.useContext(I18nextContext);
  // const bgBlogPostSection = useColorModeValue("black", "white");
  // const colorBlogPostSection = useColorModeValue("white", "black");
  
  return (
    <Container>
      <Helmet 
        title={`${siteTitle}`} 
        htmlAttributes={{
          lang: context.language,
        }}
      />
      <SEO
        title={`${siteTitle}`}
        description={description}
        image={''}
        pathname={`${context.language}/`}
      />
      <Header/>
      <Flex h="30vh"  align="flex-end" my="10" >
        <Box maxW="32rem">
          <Heading mb={4} size="2xl">{t("title")}</Heading>
          <Text fontSize="xl" mb={4}>
            {t("subtitle")}
          </Text>
          <Button>{t("cta")}</Button>
        </Box>
      </Flex>
      <Flex 
        justify="space-between"
        wrap="wrap"
        my="auto"
      >
        {posts.map(({ node }) => {
            return (
              <BlogPostPreview article={node} key={node.slug}/>
            )
          })}
      </Flex>
      <Footer/>
    </Container>
  )
}

export default About;

export const pageQuery = graphql`
  query BlogAboutQuery($language: String!) {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, filter: {node_locale: { eq: $language }}) {
      edges {
        node {
          title
          slug
          node_locale
          publishDate(formatString: "MMMM DD, YYYY", locale: $language)
          tags
          heroImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    locales: allLocale(filter: {ns: {in: ["home", "header"]}, language: {eq: $language}})  {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`