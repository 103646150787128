import React from 'react';
import { Link as GatsbyLink } from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import {
  Box,
  Text,
  Heading,
  Link,
  Image,
  VStack,
  HStack,
  Badge
} from "@chakra-ui/react";

export default ({ article }) => {
  const locale = article.node_locale;
  return (
    <Box maxW="xs" borderRadius="lg" overflow="hidden" my="5">
      <Image borderRadius="lg" as={GatsbyImage} alt="" image={article.heroImage.gatsbyImageData} />
      <VStack
        align="flex-start"
        pt={2}
        spacing={2}
      >
        <HStack justify="center">
          <Text as="span" fontWeight="300" fontSize="sm">{article.publishDate}</Text>
          {article.tags?.map(tag => 
            <Badge fontSize="11">
              {tag}
            </Badge>
          )}
        </HStack>
        <Heading
          fontWeight="semibold"
          as="h2" 
          size="md"
        >
          <Link as={GatsbyLink} to={`/${locale}/${article.slug}`}>{article.title}</Link>
        </Heading>
        <Text
          as="p" 
          fontWeight="300" 
          fontSize="sm"
          dangerouslySetInnerHTML={{
            __html: article.description.childMarkdownRemark.html,
          }}
        />
      </VStack>
    </Box>
  )
}
