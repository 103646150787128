import React from 'react';

import { 
  Flex, 
  Box, 
  Heading, 
  Spacer,
  IconButton, 
  Button, 
  Link, 
  Icon, 
  useColorMode,
  Text,
  HStack
 } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby"
import { FaDice, FaLightbulb } from "react-icons/fa"
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation("header");
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <Flex h="200px" maxH="100px" alignItems="center">
      {/* <Box>
        <Heading as={GatsbyLink} to="/" size="md">Serenpit</Heading>
      </Box> */}
      <Spacer />
    </Flex>
  )
}

export default Footer;